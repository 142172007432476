import React, { useState, useRef } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faEnvelope,
  faPhone,
  faMapMarker,
} from "@fortawesome/free-solid-svg-icons"
import { FormattedMessage, useIntl } from "gatsby-plugin-intl"
import {
  Button,
  Input,
  Box,
  Textarea,
  Heading,
  Text,
  Container,
  Flex,
} from "@chakra-ui/react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i

const ContactPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const formRef = useRef(null)
  const [values, setValues] = useState({
    name: "",
    email: "",
    message: "",
  })
  const [errors, setErrors] = useState({})

  const validateForm = e => {
    e.preventDefault()
    const formErrors = {}
    if (values.name.trim() === "") formErrors.name = "Navn mangler"
    if (values.email.trim() === "" || !emailRegex.test(values.email))
      formErrors.email = "Skriv inn en gyldig epost"
    if (values.message.trim() === "") formErrors.message = "Melding mangler"
    if (Object.keys(formErrors).length > 0) setErrors(formErrors)
    else formRef.current?.submit()
  }

  const validateName = () => {
    if (values.name.trim() === "")
      setErrors({ ...errors, name: "Navn mangler" })
    else setErrors({ ...errors, name: "" })
  }

  const validateEmail = () => {
    if (values.email === "" || !emailRegex.test(values.email))
      setErrors({ ...errors, email: "Skriv inn en gyldig epost" })
    else setErrors({ ...errors, email: "" })
  }
  const validateMessage = () => {
    if (values.message.trim() === "")
      setErrors({ ...errors, message: "Melding mangler" })
    else setErrors({ ...errors, message: "" })
  }

  const intl = useIntl()

  return (
    <Layout location={location} title={siteTitle} container>
      <SEO title="Kontakt" />
      <Flex marginTop={20} direction={["column", "column", "row", "row"]}>
        <Box flex="1">
          <Container marginBottom="10">
            <Heading size="lg">
              <FormattedMessage id="contactContactUs" />
            </Heading>
            <form
              ref={formRef}
              id="contactForm"
              method="post"
              data-netlify="true"
              netlify-honeypot="bot-field"
              name="contact"
              action="/success"
            >
              <input type="hidden" name="bot-field" />
              <input type="hidden" name="form-name" value="contact" />
              <Input
                type="text"
                value={values.name}
                onChange={e => setValues({ ...values, name: e.target.value })}
                onBlur={validateName}
                name="name"
                id="name"
                placeholder={intl.formatMessage({ id: "fullName" })}
                marginTop="3"
                isInvalid={errors.name}
              />
              {errors.name && <Text>{errors.name}</Text>}
              <Input
                type="email"
                value={values.email}
                onChange={e => setValues({ ...values, email: e.target.value })}
                onBlur={validateEmail}
                name="email"
                id="email"
                placeholder={intl.formatMessage({ id: "email" })}
                marginTop="3"
                isInvalid={errors.email}
              />
              {errors.email && <Text>{errors.email}</Text>}
              <Textarea
                name="message"
                id="message"
                placeholder={intl.formatMessage({ id: "message" })}
                rows="4"
                value={values.message}
                onChange={e =>
                  setValues({ ...values, message: e.target.value })
                }
                onBlur={validateMessage}
                marginTop="3"
                isInvalid={errors.message}
                minHeight="100px"
                maxHeight="500px"
              />
              {errors.message && <Text>{errors.message}</Text>}
              <Button
                onClick={validateForm}
                className="primary"
                disabled={
                  values.name === "" ||
                  values.email === "" ||
                  values.message === ""
                }
                marginTop="2"
              >
                {intl.formatMessage({ id: "sendMessage" })}
              </Button>
            </form>
          </Container>
        </Box>
        <Box flex="1">
          <Container>
            <Heading size="lg" marginBottom="5">
              <FontAwesomeIcon className="sc-gallery-icon" icon={faPhone} />{" "}
              <a href="tel:93204600">932 04 600</a>
            </Heading>
            <Heading size="md" marginBottom="5">
              <FontAwesomeIcon className="sc-gallery-icon" icon={faEnvelope} />{" "}
              <a href="mailto:onf@oppdal.com">onf@oppdal.com</a>
            </Heading>
            <Heading size="md" marginBottom="10">
              <FontAwesomeIcon className="sc-gallery-icon" icon={faMapMarker} />{" "}
              c/o Visit Oppdal, O. Skasliens veg 1
            </Heading>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1836.4679023459596!2d9.686046616206387!3d62.59426788291781!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x461353fa7c564d2d%3A0xd2a5c95d33adc3fa!2sInge%20Krokanns%20veg%209%2C%207340%20Oppdal!5e0!3m2!1sno!2sno!4v1623435375232!5m2!1sno!2sno"
              frameBorder="0"
              style={{
                border: 0,
                width: "100%",
                height: "400px",
                borderRadius: "20px",
              }}
              allowFullScreen=""
              aria-hidden="false"
              tabIndex="0"
            ></iframe>
          </Container>
        </Box>
      </Flex>
    </Layout>
  )
}

ContactPage.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
}

export default ContactPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
